export const getUrlParams = ()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of urlParams.entries()) {
        params[key] = value;
    }

    return params;
}


/**
 * 获取当前时间 时分秒
 */
export const getCurrentTime = ()=>{
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const currentTime = `${year}-${month}-${day} ${hour}:00:00`;
    return currentTime;
}
