<template>
  <div>
    <van-nav-bar fixed border title="手动报修" left-text="返回" @click-left="router.back">
      <template #left>
        <img src="../../../asset/form/back.png" alt="返回箭头" class="nav-left-img">
      </template>
    </van-nav-bar>

    <div class="page-container prepare">
      <van-form @submit="onSubmit" ref="formRef">
        <van-cell-group inset>
          <van-field label="手机"
                     placeholder="请输入您的手机号"
                     name="clientCellphone"
                     required
                     v-model.trim="form.clientCellphone"
                     :rules="[{ required: true, message: '请填写手机号' }]"/>

          <van-field label="姓名"
                     placeholder="请输入您的姓名"
                     name="clientName"
                     required
                     v-model.trim="form.clientName"
                     :rules="[{ required: true, message: '请填写姓名' }]"/>

          <van-field label="地址"
                     placeholder="工程师将根据此地址上门服务"
                     name="clientAddress"
                     required
                     v-model.trim="form.clientAddress"
                     :rules="[{ required: true, message: '请填写详细地址' }]"/>

          <van-field label="预约时间"
                     placeholder="请选择预约时间"
                     name="appointmentTime"
                     readonly
                     required
                     clickable
                     @click="showPicker = true"
                     v-model="form.appointmentTime"
                     :rules="[{ required: true, message: '请选择预约上门时间' }]"/>

          <van-popup v-model:show="showPicker" position="bottom">
            <van-datetime-picker type="datetime"
                                 :min-date="new Date()"
                                 @confirm="onConfirm"
                                 @cancel="showPicker = false"/>
          </van-popup>

          <van-field label="问题描述"
                     placeholder="请填写问题描述"
                     class="description"
                     v-model.trim="form.description"
                     required
                     autosize
                     clearable
                     show-word-limit
                     type="textarea"
                     maxlength="128"
                     name="description"
                     :rules="[{ required: true, message: '请填写问题描述' }]"/>

          <van-field name="files" label="文件上传">
            <template #input>
              <van-uploader multiple
                            v-model="form.files"
                            :max-count="3"
                            :max-size="1024 * 10 * 1024"
                            @oversize="onOversize"/>

            </template>
          </van-field>

          <van-row>
            <van-col span="16">
              <van-field label="验证码"
                         placeholder="请输入验证码"
                         :left-icon="require('../../../asset/form/captcha.png')"
                         name="captcha"
                         required
                         clearable
                         v-model.trim="form.captcha"
                         :rules="[{ validator: validationCaptcha, message: '请输入正确内容' }]"/>
            </van-col>
            <van-col span="8">
              <van-image width="108" height="40" :src="captchaUrl" @click="getCaptcha"/>
            </van-col>
          </van-row>

          <van-cell>
            <van-button round block type="primary" native-type="submit" class="submit" :loading="loading">提交
            </van-button>
          </van-cell>
        </van-cell-group>
      </van-form>
    </div>
    <div class="page-background"></div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {Dialog, Toast} from "vant";
import {useRouter} from "vue-router";
import axios from "@/plugin/axios";
import dd from "gdt-jsapi"//专有钉钉
import {getUrlParams} from "../../../plugin/tools";
import {userInfo} from "../../../api/ding";

onMounted(() => {
  validationUrlParam();
  getDingUserInfo();//获取钉钉用户信息
  form.clientCellphone = localStorage.getItem("createWorkorderPhone")
  form.clientName = localStorage.getItem("createWorkorderName")
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth().toString().padStart(2,0);
  let d = date.getDate().toString().padStart(2,0);
  let hour = date.getHours().toString().padStart(2,0);
  let m = date.getMinutes().toString().padStart(2,0)
  //form.appointmentTime = year+"-"+month+ "-" + d + " " +hour+":"+m;
  getCaptcha();
});

const router = useRouter();
const formRef = ref(null);

const captchaUrl = ref("");
// 验证码错误信息提示
const errorMessage = ref("");
// 时间选择器
let showPicker = ref(false);
// 控制验证码的弹窗
const show = ref(false);

const loading = ref(false);

const query = getUrlParam();
// 表单
const form = reactive({
  clientCellphone: "",
  clientName: "",
  clientAddress: "",
  appointmentTime: "",
  description: "",
  captchaId: "",
  captcha: "",
  files: []
});

const onConfirm = value => {
  const dateTime = new Date(value);
  let year = dateTime.getFullYear();
  let month = (dateTime.getMonth() + 1).toString().padStart(2, 0);
  let day = dateTime.getDate().toString().padStart(2, 0);
  let hour = dateTime.getHours().toString().padStart(2, 0);
  let minute = dateTime.getMinutes().toString().padStart(2, 0);

  form.appointmentTime = `${year}-${month}-${day} ${hour}:${minute}`;
  showPicker.value = false;
};

/**
 * 限制上传图片的大小
 */
const onOversize = () => {
  Toast("文件大小不能超过 10M");
};

/**
 *  获取钉钉用户信息
 */
const getDingUserInfo = () => {
  dd.hideTitleBar()
  dd.ready( ()=> {
    dd.getAuthCode({}).then((result) =>{
      if (result) {
        var code = result.code;//获取授权登录码
        getDingUser( code )
      }
    }).catch(err => {
      console.warn(err)
    })
  })
};

/**
 *  获取钉钉用户信息
 */
const getDingUser = (authCode) => {
  userInfo({authCode}).then(res => {
    let {account, lastName} = res.data;
    form.clientCellphone = account!==null?account:localStorage.getItem("createWorkorderPhone");
    form.clientName = lastName!==null?lastName:localStorage.getItem("createWorkorderName");
    if (account){
      localStorage.setItem("createWorkorderPhone",account)
      localStorage.setItem("createWorkorderName",lastName)
    }
  }).catch(error => {
    console.warn("Get ding user info fail", error.response);
  });
};



/**
 * 刷新验证码
 */
const getCaptcha = () => {
  axios.get("/workorder/workorder/prepare/captcha").then(res => {
    captchaUrl.value = res.data.base64Image;
    form.captchaId = res.data.captchaId;
    errorMessage.value = "";
  }).catch(error => {
    console.warn("Get workorder prepare captcha fail", error.response);
  });
};

/**
 * 校验验证码
 * @param value 验证码
 */
const validationCaptcha = value => {
  if (!!value) {
    if (!!errorMessage.value) {
      return errorMessage.value;
    } else {
      return true;
    }
  } else {
    return "请输入验证码";
  }
};

const handle = (formData) => {
  axios.post("/workorder/workorder/prepare", formData).then(response => {
    loading.value = false;
    Dialog.alert({
      title: "提交成功",
      confirmButtonText: "返回首页"
    }).then(() => {
      router.push({path: "/", query: getUrlParam()});
    });
  }).catch(error => {
    loading.value = false;
    if (error.status === 40000 || error.status === 40400) {
      errorMessage.value = error.data;
      formRef.value.validate("captcha");
      getCaptcha();
    }
  });


}




/**
 * 提交表单
 */
const onSubmit = () => {
  localStorage.setItem("createWorkorderPhone",form.clientCellphone)
  localStorage.setItem("createWorkorderName",form.clientName);
  let formData = new FormData();
  formData.append("clientCellphone", form.clientCellphone);
  formData.append("clientName", form.clientName);
  formData.append("clientAddress", form.clientAddress);
  formData.append("description", form.description);
  formData.append("appointmentTime", form.appointmentTime);
  formData.append("captcha", form.captcha);
  formData.append("captchaId", form.captchaId);
  !!query.origin ? formData.append("origin", query.origin) : formData.append("origin", "prepare");
  if (form.files.length > 0) {
    form.files.forEach(item => {
      formData.append("files", item.file);
    });
  }
  formData.append("headers", {"Content-Type": "multipart/form-data"});
  loading.value = true;
  Dialog.confirm({
    title: '温馨提示',
    message: `请再次确认本次报修工单联系方式为:${form.clientCellphone}。后续工程师将通过此手机号码联系您，并只能通过该手机号码接收短信和查看工单进度。`,})
      .then(() => {
        handle(formData) // 提交表单
      })
      .catch(() => {
        Toast("取消提交");
        loading.value = false;
      });

};

</script>

<style scoped>
.prepare {
  height: calc(100vh - 76px);
}

.nav-left-img {
  width: 30px;
}

/deep/ .van-nav-bar__title {
  color: white;
}

/deep/ .van-field__left-icon {
  display: flex;
  align-items: center;
}

/deep/ .van-icon__image {
  width: 1.3em;
  height: 1.3em;
}

.description /deep/ .van-field__left-icon {
  align-items: start;
}

/deep/ .van-nav-bar--fixed {
  background-color: #0546EF;
}

.van-hairline--bottom:after {
  border-bottom-width: 0;
}

.page-background {
  z-index: -1;
  width: 100%;
  height: 70px;
  position: absolute;
  top: 46px;
  background-color: #0546EF;
}

/deep/ .van-cell-group--inset {
  margin: 20px 0 0 0;
  padding-top: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
</style>
